<template>
  <div v-loading="pageloading">
    <cut-in v-if="Access<4" :font="'自定义版交易组件'"></cut-in>
    <div v-else>
      <el-card>
        <div class="title">直播间分享员</div>
        <div class = "titleFont" >1.邀请商城的推手或店员成为视频号的分享员，为视频号直播推广引流；</div>
        <div  class = "titleFont">2.客户通过分享员推广的直播间链接进入直播间→进入小程序即可与分享员锁粉，后续交易成交，分享员可获得推手收益或员工提成；</div>
        <div class = "titleFont">3.你可以通过3种方式邀请他们成为直播间分享员：批量导入邀请、手动添加邀请、授权成为推手自动邀请。</div>
        <el-button type="text" style="padding:0px">
          <a href="https://jusnn6k8al.feishu.cn/docx/doxcn6XfwekIyMfquX3N3k0yneR" style="line-height:2" target="_blank">查看直播间分享员使用说明</a>
        </el-button>
      </el-card>
      <el-card style="margin-top:10px">
        <div slot="header">
          <div class="title">我的分享员</div>
          <div class="flexRow">
            <div class="titleFont" style="margin-right:10px">最多可绑定10000个分享员，还可绑定{{10000-pageinit.BindSharerCount}}个</div>
            <div class="flexRow" v-if="pageinit.BindSharerCount<10000">
              <buttonPermissions :datas="'sharememberinvitation'" style="margin-right:10px">
                <el-button type="primary" @click="invitation(1)">邀请</el-button>
              </buttonPermissions>
              <buttonPermissions :datas="'sharememberbatchinvitation'" style="margin-right:10px">
                <el-button @click="invitation(2)">批量邀请</el-button>
              </buttonPermissions>
            </div>
            <div class="flexRow" v-else>
              <buttonPermissions :datas="'sharememberinvitation'" style="margin-right:10px">
                <el-popover placement="top" width="300" trigger="hover" content="小程序绑定的分享员达到上限10000个，若要继续邀请，请解绑已绑定的分享员">
                  <div class="elbutton" slot="reference">邀请</div>
                </el-popover>
              </buttonPermissions>
              <buttonPermissions :datas="'sharememberbatchinvitation'" style="margin-right:10px">
                <el-popover placement="top" width="300" trigger="hover" content="小程序绑定的分享员达到上限10000个，若要继续邀请，请解绑已绑定的分享员">
                  <div class="elbutton" slot="reference">批量邀请</div>
                </el-popover>
              </buttonPermissions>
            </div>
            <buttonPermissions :datas="'sharememberinvitationsetup'" style="margin-right:10px">
              <el-button type="text" style="margin-left:10px" @click="setShow=true">邀请设置</el-button>
            </buttonPermissions>
            <el-button type="text" @click="$router.push({path:'/setting/liveVideo/sharemember/exportlist'})">导入记录</el-button>
          </div>
        </div>
        <div class="flexRow">
          <div class="flexRow" style="margin-right:10px">
            <div class="label-font">关键字：</div>
            <el-input v-model="keywords" placeholder="微信昵称、手机号" style="width:200px"></el-input>
          </div>
          <div class="flexRow" style="margin-right:10px">
            <div class="label-font">绑定时间：</div>
            <el-date-picker
              v-model="startTime"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
            <span style="margin:0px 10px">~</span>
            <el-date-picker
              v-model="endTime"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </div>
          <el-button type="primary" @click="toquery">查询</el-button>
        </div>
        <div class="flexRow" style="margin-top:15px;justify-content: space-between;">
          <div class="flexRow">
            <div class="label-font">状态：</div>
            <el-radio-group v-model="radio" @change="toquery">
              <el-radio v-for="(v,i) in radiolist" :key="i" :label="v.value">{{v.label}}</el-radio>
            </el-radio-group>
          </div>
          <div style="text-align:right">
            <el-link icon="el-icon-warning" href="https://jusnn6k8al.feishu.cn/docx/doxcnT7AE8KVgY323Uxc19u1Ble"
               target="_blank" :underline="false">数据统计说明</el-link>
            <div class="grayfont">(数据更新至昨日，每日凌晨四点更新)</div>
          </div>
        </div>
        <div style="margin-top:10px">
          <el-table :data="tablelist" v-loading="tableloading">
            <el-table-column prop="" label="分享员" key="1" min-width="200px">
              <template slot-scope="scope">
                <div class="flexRow">
                  <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaultheader" style="width:50px;height:50px;border-radius:4px" alt="">
                  <div style="margin-left:5px">
                    <div class="ellipsis">
                      {{scope.row.WxNickname}}
                      <span v-if="scope.row.Name">({{scope.row.Name}})</span>
                    </div>
                    <div class="flexRow">
                      {{scope.row.Phone}}
                      <div class="tag" v-if="scope.row.IdentityType==2">锁粉失效</div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="带货总额(元)" key="2" v-if="radio==1">
              <template slot-scope="scope">
                <div>
                  ￥{{scope.row.OrderProductMoney.toFixed(2)}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="OrderCount" label="带货订单数" key="3" v-if="radio==1"></el-table-column>
            <el-table-column prop="OrderMemberCount" label="带货用户数" key="4" v-if="radio==1"></el-table-column>
            <el-table-column prop="" label="退款总额(元)" key="5" v-if="radio==1">
              <template slot-scope="scope">
                <div>
                  ￥{{scope.row.RefundProductMoney.toFixed(2)}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="带货收益(元)" key="6" v-if="radio==1">
              <template slot-scope="scope">
                <div>
                  ￥{{scope.row.AwardMoney.toFixed(2)}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="BindTime" label="绑定时间" key="7" v-if="radio==1"></el-table-column>
            <el-table-column prop="InvitationTime" label="邀请时间" key="8" v-else></el-table-column>
            <el-table-column prop="FailureReason" label="失败原因" key="9" v-if="radio==2"></el-table-column>
            <el-table-column prop="" label=" 操作" key="10" v-if="radio==1">
              <template slot-scope="scope">
                <div class="flexRow">
                  <buttonPermissions :datas="'sharemembercargodetail'" style="margin-right:10px">
                    <el-button type="text" @click="todetail(scope.row)">带货明细</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas="'sharememberunbundling'" style="margin-right:10px">
                    <el-button type="text" style="color:#f56c6c" @click="unbundling(scope.row)">解绑</el-button>
                  </buttonPermissions>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top:10px;text-align:right" v-if="total">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pagesize"
              layout="total, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </el-card>
      <el-dialog :visible.sync="invitationShow" title="邀请分享员" @close="close">
        <div style="display:flex">
          <div class="management-label">客户：</div>
          <div style="flex:1">
            <div class="flexRow">
              <el-input v-model.trim="managementKeywords" placeholder="请输入手机号" style="width:200px;margin-right:10px"></el-input>
              <el-button type="primary" @click="search">查询</el-button>
            </div>
            <div class="grayfont" style="margin-top:5px">输入推手或员工手机号查找；可查找多个数据，批量邀请</div>
            <div class="flexRow" v-loading="searchloading" style="flex-wrap:wrap;overflow:auto;max-height:320px;padding-top:20px;min-height:60px">
              <div class="customerBox" v-for="(v,i) in managementCustomer" :key="i">
                <div class="imgbox">
                  <img :src="v.WxHeadUrl?v.WxHeadUrl:defaultheader" style="border-radius:5px;width:100%" alt="">
                  <div class="closeIcon" @click="clear(i)">
                    <i class="el-icon-error"></i>
                  </div>
                </div>
                <div class="phone">{{v.Phone}}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top:10px;text-align:right">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" :loading="managementLoading" @click="confirm">确认</el-button>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="failShow" title="分享员邀请失败列表" @close="close2">
        <div>
          <div class="grayfont">
            以下推手/员工邀请失败：
          </div>
          <el-table :data="faillist" max-height="500px">
            <el-table-column prop="" label="分享员">
              <template slot-scope="scope">
                <div class="flexRow">
                  <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaultheader" style="width:50px;height:50px;border-radius:4px" alt="">
                  <div style="margin-left:5px">
                    <div class="ellipsis">
                      {{scope.row.WxNickname}}
                      <span v-if="scope.row.Name">({{scope.row.Name}})</span>
                    </div>
                    <div>
                      {{scope.row.Phone}}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="FailureReason" label="失败原因"></el-table-column>
          </el-table>
          <div style="margin-top:10px;text-align:right">
            <el-button @click="close2">关闭</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="batchinvitationShow" title="批量邀请分享员" @close="close3">
        <div style="display:flex">
          <div style="line-height:36px;margin-right:5px">上传表格：</div>
          <el-upload :action="uploadUrl" :on-change="changeUpload" ref="upload" :on-remove="onRemove" :before-upload="beforeUpload"
            accept=".xls,.xlsx,.xlt" :file-list="fileList" style="margin-bottom:20px" :on-success="handleUploadSuccess">
            <div class="flexRow">
              <el-button size="small">选择文件</el-button>
              <div class="titleFont" style="margin-left:5px" v-if="!fileList||!fileList.length">未选择任何文件</div>
            </div>
          </el-upload>
        </div>
        <div class="bluebox">
          <div class = "titleFont" >操作提示：</div>
          <div class = "titleFont" >1.请在模板中输入推手或员工手机号，
            <el-button type="text" style="margin:0px;padding:0px" @click="exporttemplate">点击下载批量添加分享员模板</el-button>
          </div>
          <div class = "titleFont" >2.若导入的手机号重复，系统将会自动过滤</div>
          <div class = "titleFont" >3.单次导入最多支持3000行数据，如超过请分多次操作</div>
          <div class = "titleFont" >4.若出现导入失败的手机号，可下载失败数据查看具体原因</div>
          <div class = "titleFont" >5.推手或员工需登录过商城小程序，否则无法导入添加</div>
          <div class = "titleFont" >6.导入邀请成功后，需要他们在微信上接受邀请，才能正式绑定成为分享员</div>
        </div>
        <div style="margin-top:10px;text-align:right">
          <el-button @click="close3">取消</el-button>
          <el-button type="primary" :loading="batchinvitationloading" @click="confirmUpload">确认</el-button>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="setShow" title="分享员邀请设置" width="550px" @close="close4">
        <div style="display:flex">
          <div class="titleFont">自动邀请：</div>
          <div style="padding:3px 15px">
            <el-radio-group v-model="pageinit.IsOpenAutoInvitationSharer">
              <el-radio :label="true">开启</el-radio>
              <el-radio :label="false">关闭</el-radio>
            </el-radio-group>
            <div class="grayfont" style="margin-top:5px">开启后，当客户成为推手时，自动给客户发送绑定分享员邀请</div>
          </div>
        </div>
        <div style="margin-top:20px;text-align:right">
          <el-button @click="close4">取消</el-button>
          <el-button type="primary" :loading="setloading" @click="confirmset">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import cutIn from "../components/cutIn"
import buttonPermissions from '@/components/buttonPermissions';
import config from "@/config"
import {
  videolivevideoliveapplyinfo,
  videoLiveSharerinit,
  videoLiveSharermodifyInvitationConfig,
  videoLiveSharerlist,
  videoLiveSharerunbind,
  videoLiveSharerinvitationSearch,
  videoLiveSharerinvitationinvitationSubmit,
  videoLiveSharerinvitationImport,
}from "@/api/sv1.0.0"
export default {
  components: {
    cutIn,
    buttonPermissions
  },
  data () {
    return {
      imgUrl:config.IMG_BASE,
      defaultheader:config.DEFAULT_HEADER,
      uploadUrl: config.UPLOAD_ZIP,
      Access:0,
      VideoLiveId:'',
      pageinit:{},
      pageloading:false,
      keywords:'',
      startTime:'',
      endTime:'',
      radio:1,
      radiolist:[
        {value:1,label:'已绑定'},
        {value:0,label:'邀请中'},
        {value:2,label:'邀请失败'},
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,

      invitationShow:false,
      searchloading:false,
      managementKeywords:'',
      managementCustomer:[],
      managementLoading:false,

      failShow:false,
      faillist:[],

      batchinvitationShow:false,
      fileList:[],
      file:{},
      batchinvitationloading:false,

      setShow:false,
      isautomatic:false,
      setloading:false,
    }
  },
  async created () {
   await this.getvideolivevideoliveapplyinfo()
   if(this.Access>=4){
     this.init()
      this.gettablelist()
    }
  },
  methods:{
    async getvideolivevideoliveapplyinfo(){
      this.pageloading = true
      let result = await videolivevideoliveapplyinfo()
      if(result.IsSuccess){
        // result.Result.State = 0
        this.Access = result.Result.State
        this.VideoLiveId  =result.Result.VideoLiveId
      }
      this.pageloading = false
    },
    async init(){
      try{
        this.pageloading = true
        let res = await videoLiveSharerinit()
        if(res.IsSuccess){
          // console.log(res.Result)
          this.pageinit = res.Result
          this.radiolist = [
            {value:1,label:`已绑定(${res.Result.BindSharerCount})`},
            {value:0,label:`邀请中(${res.Result.InvitingSharerCount})`},
            {value:2,label:`邀请失败(${res.Result.InvitationFailureSharerCount})`},
          ]
        }
      }finally{
        this.pageloading = false
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    toquery(){
      this.currentPage = 1
      this.init()
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          InvitationStatus:this.radio,
          Keywords:this.keywords,
          StartBindTime:this.startTime,
          EndBindTime:this.endTime,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        }
        let res = await videoLiveSharerlist(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    async invitation(num){
      // await this.getvideolivevideoliveapplyinfo()
      // // this.VideoLiveId = 0
      // if(!this.VideoLiveId){
      //   const h = this.$createElement
      //   this.$msgbox({
      //     type: 'warning',
      //     title: '消息',
      //     message: h('p', null, [
      //         h('span', null, '小程序未绑定视频号，请先前往绑定。'),
      //         h('el-link', {attrs:{
      //             type:'primary',
      //             target:"_blank",
      //             underline:false,
      //             href:"https://jusnn6k8al.feishu.cn/docs/doccnkTHOZa6hbFWTvnwNkCwrSf?from=from_copylink",
      //           },}, '查看绑定说明')
      //         ]),
      //     showCancelButton: true,
      //     confirmButtonText: '去绑定',
      //     cancelButtonText: '取消',
      //   }).then(res=>{
      //     this.$router.push({
      //       path:'/setting/liveVideo/liveCutIn',
      //       query:{
      //         activeName:'2'
      //       }
      //     })
      //   })
      //   return 
      // }
      if(num==1){
        this.invitationShow = true
      }else if(num==2){
        this.batchinvitationShow = true
      }
    },
    async search(){
      if(!this.managementKeywords){
        this.$message.error('请输入手机号')
        return
      }else if(this.managementKeywords.length!=11){
        this.$message.error('请输入完整的11位手机号')
        return
      }else if(this.managementCustomer.some(v=>v.Phone==this.managementKeywords)){
        this.$message.error('该手机号已添加，请勿重复查询')
        return 
      }
      try{
        this.searchloading = true
        let data = {
          Phone:this.managementKeywords
        }
        let res = await videoLiveSharerinvitationSearch(data)
        if(res.IsSuccess){
          // console.log(res.Result)
          this.managementCustomer.unshift(res.Result)
        }
      }finally{
        this.searchloading = false
      }
    },
    clear(i){
      this.managementCustomer.splice(i,1)
    },
    close(){
      this.managementKeywords = ''
      this.managementCustomer  =[]
      this.invitationShow = false
    },
    close2(){
      this.faillist=[]
      this.failShow=false
    },
    close3(){
      this.fileList = []
      this.file = {}
      this.$refs.upload.clearFiles()
      this.batchinvitationShow = false
    },
    async confirm(){
      if(!this.managementCustomer.length){
        this.$message.error('请输入手机号，查询并添加需要发送邀请的推手或员工')
        return
      }
      try{
        this.managementLoading = true
        let data = {
          SharerList:this.managementCustomer,
          noError:true
        }
        let res = await videoLiveSharerinvitationinvitationSubmit(data)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.close()
          this.init()
          this.gettablelist()
        }else{
          this.failShow = true
          this.fileList = res.Result
        }
      }finally{
        this.managementLoading = false
      }
    },
    changeUpload(file,list){
      if(this.fileList.length>0){
        this.fileList = []
        this.$refs.upload.clearFiles()
      }
      this.fileList.push(file)
    },
    async confirmUpload(){
      if(!this.fileList.length){
        this.$message.error('请上传导入文件')
        return
      }
      try{
        this.batchinvitationloading = true
        //文件上传成功，新标签页自动打开导入记录列表页
        // console.log(this.file)
        let data = {
          ExcelFilePath:this.file.url,
          ExcelFileName:this.file.name
        }
        let res = await videoLiveSharerinvitationImport(data)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.close3()
          this.$router.push({
            path:'/setting/liveVideo/sharemember/exportlist'
          })
        }
      }finally{
        this.batchinvitationloading = false
      }
    },
    handleUploadSuccess(file){
      // console.log(file)
      this.fileList = file
      this.file.url = file[0]
    },
    beforeUpload(file){
      var type = file.name.split('.')[1];
      if (['xls', 'xlsx'].indexOf(type) > -1){
        this.file.name = file.name;
        return true;
      }else{
        return false;
      }
    },
    exporttemplate(){
      window.open('http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E9%82%80%E8%AF%B7%E5%88%86%E4%BA%AB%E5%91%98.xlsx')
    },
    onRemove(file){
      this.fileList = []
      this.file = {}
      this.$refs.upload.clearFiles()
    },
    close4(){
      this.isautomatic = false
      this.setShow = false
    },
    async confirmset(){
      try{
        this.setloading = true
        let data = {
          IsOpenAutoInvitationSharer:this.pageinit.IsOpenAutoInvitationSharer
        }
        let res = await videoLiveSharermodifyInvitationConfig(data)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.close4()
          this.init()
        }
      }finally{
        this.setloading = false
      }
    },
    async unbundling(row){
      const h = this.$createElement
      this.$msgbox({
        type: 'warning',
        title: '消息',
        message: h('div', null, [
              h('div',null,[
                h('span', null, '解除绑定后，需要'),
                h('span', {style:"color:#f56c6c"}, '一个月后'),
                h('span', null, '才可重新绑定。'),
              ]), 
              h('div',null,[
                h('span', null, `是否确认解绑【${row.WxNickname+'('+row.Phone+')'}】？`),
              ]),
            ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async res=>{
        try{
          this.tableloading = true
          let data = {
            Id:row.Id
          }
          let res = await videoLiveSharerunbind(data)
          if(res.IsSuccess){
            this.$message.success('解绑成功')
            this.init()
            this.gettablelist()
          }
        }finally{
          this.tableloading = false
        }
      })
    },
    todetail(row){
      this.$router.push({
        path:'/setting/liveVideo/sharemember/detail',
        query:{
          Id:row.Id
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.tag{
  background: #FFFFFF;
  border: 1px solid #FDA23C;
  border-radius: 0px 0px 0px 0px;
  padding: 2px 4px;
  font-size: 12px;
  color: #FDA23C;
  line-height: 12px;
  font-weight: 400;
}
.title{
  font-size: 18px;
  font-weight: bold;
  line-height: 2;
  color: #303133;
}
.titleFont{
  font-size: 14px;
  color: #606266;
  line-height:2
}
.flexRow{
  display: flex;
  align-items: center;
}
.elbutton{
  background: #F5F7FA;
  color: #C0C4CC;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: default;
  border:1px solid #E4E7ED
}
.label-font{
  font-size: 14px;
  color: #606266;
  line-height: 1.5;
  white-space: nowrap;
  font-weight: bold;
  min-width: 70px;
  text-align: right;
}
.ellipsis {
  overflow     : hidden;
  text-overflow: ellipsis;
  white-space  : nowrap;
}
.grayfont{
  font-size: 12px;
  color: #999999;
  line-height: 1.5;
}
.management-label{
  flex-shrink:0;
  line-height:36px;
  width:70px;
  text-align:right;
  font-weight:bold;
  margin-right:10px
}
.customerBox{
  // border:1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px 20px 0px;
  .imgbox{
    position: relative;
    top: 0px;
    left: 0px;
    img{
      width: 80px;height: 80px;
      border-radius: 2px;
    }
    .closeIcon{
      position: absolute;
      left: 70px;
      top: -10px;
      // border: 1px solid red;
      cursor: pointer;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .phone{
    font-size: 14px;
    color: #606266;
    line-height: 1.5;
    margin: 5px 0px;
  }
}
.bluebox{
  border: 1px solid #409EEF;
  background: #FBFDFF;
  border-radius: 2px;
  padding: 10px;
}
</style>